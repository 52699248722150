import themeV2 from '../dap';
import { mgPrisBlue } from './colors';
import { Theme, alpha, createTheme } from '@mui/material';
import { componentsStyles } from './compontentsStyles';
import { typography } from './typography';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
    '3xl': true;
  }
}

const mgPrisTheme: Theme = createTheme({
  ...themeV2,
  typography: typography,
  palette: {
    ...themeV2.palette,
    primary: {
      ...mgPrisBlue,
      main: mgPrisBlue[800],
      dark: mgPrisBlue[900],
      light: mgPrisBlue[500],
      contrastText: themeV2.palette.common.white,
    },
    secondary: {
      ...themeV2.palette.secondary,
      main: themeV2.palette.secondary[900],
      dark: themeV2.palette.secondary.A100,
      contrastText: themeV2.palette.common.white,
    },

    grey: {
      ...themeV2.palette.grey,
      50: '#FAFAFA',
      300: '#F9FAFB',
    },
    text: {
      primary: themeV2.palette.grey[800],
      secondary: themeV2.palette.grey.A400,
      disabled: themeV2.palette.grey.A200,
    },
    error: {
      light: themeV2.palette.warning[900],
      main: themeV2.palette.error.main,
      dark: themeV2.palette.error.dark,
      contrastText: themeV2.palette.text.primary,
    },
    gradient: {
      ...themeV2.palette.gradient,
      primary: `linear-gradient(292deg, ${mgPrisBlue[800]} 15.01%, ${mgPrisBlue[700]} 32.03%, ${mgPrisBlue[600]} 50.5%, ${mgPrisBlue[500]} 98.63%)`,
      tertiary: `linear-gradient(292deg, #6E1FBC 15.01%, #8A13B4 32.03%, #991AC6 50.5%, #CA2CCD 98.63%)`,
    },
    background: {
      ...themeV2.palette.background,
      default: mgPrisBlue.A200,
      secondary: mgPrisBlue.A100,
    },
    divider: alpha(themeV2.palette.grey[200], 0.4),
  },
  components: componentsStyles,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000,
      '3xl': 2500,
    },
  },
});

export default mgPrisTheme;
