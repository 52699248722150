import { Typography, Stack, StackProps, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Chip } from '@shared/components';

interface Tag {
  title: string;
  slug: string;
  href: string;
}

interface TagsListProps {
  title?: string;
  tags: Tag[];
  sx?: StackProps['sx'];
}

export function TagsList({ title, tags, sx }: TagsListProps) {
  if (!tags.length) {
    return null;
  }

  return (
    <Stack direction="column" sx={sx}>
      {title && (
        <Typography variant="h6" component="h3" marginBottom={2}>
          {title}
        </Typography>
      )}
      <Stack direction="row" alignItems="flex-start">
        <List>
          {tags.map((t) => (
            <li key={t.slug}>
              <Chip
                // @ts-ignore - "to" is not defined in Chips props
                to={t.href}
                clickable
                component={Link}
                label={t.title}
              />
            </li>
          ))}
        </List>
      </Stack>
    </Stack>
  );
}

const List = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginInlineEnd: theme.spacing(1),
}));
