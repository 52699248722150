import { CheckboxGroupOptionItem } from './types';
import { Stack, Typography } from '@mui/material';

interface CheckboxLabelWithCountProps extends Omit<CheckboxGroupOptionItem, 'value'> {
  count: number;
}

export const CheckboxLabelWithCount = ({ label, count }: CheckboxLabelWithCountProps) => (
  <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" flex={1}>
    <Typography component="span">{label}</Typography>{' '}
    <Typography component="span" color="grey.500">
      {count}
    </Typography>
  </Stack>
);
