import { useAppTFunction } from '@dap-common/i18n';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';

interface AllOptionProps extends Pick<CheckboxProps, 'name' | 'color' | 'disabled' | 'sx'> {
  selectAll: boolean;
  toggleSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export default function AllOptionCheckbox({
  selectAll,
  toggleSelectAll,
  name,
  color,
  disabled,
  sx,
}: AllOptionProps) {
  const t = useAppTFunction();
  const formLabelId = `${name}-label`;
  const selectAllValue = `select-unselect-all-${name}`;

  return (
    <FormControlLabel
      key={selectAllValue}
      label={t('common.all')}
      control={
        <Checkbox
          size="small"
          name={selectAllValue}
          checked={selectAll}
          onChange={toggleSelectAll}
          value={selectAllValue}
          id={`${selectAllValue}-${name}-id`}
          color={color}
          disabled={disabled}
          sx={sx}
        />
      }
      componentsProps={{ typography: { variant: 'body1' } }}
      id={`${selectAllValue}-${formLabelId}`}
    />
  );
}
