import { useId } from 'react';
import {
  Stack,
  styled,
  FormControl,
  FormControlProps,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  useRadioGroup,
} from '@mui/material';

import { ContainedButton } from '@shared/components';

type Size = 'small' | 'medium' | 'large';

interface RadioToggleGroupItem {
  label: string;
  value: string;
}

interface RadioToggleGroupProps {
  size?: Size;
  name: string;
  label?: string;
  labelAll: string;
  options: RadioToggleGroupItem[];
  selectedOption: string;
  onChangeOption: (name: string, value: string) => void;
  sx?: FormControlProps['sx'];
}

export const RadioToggleGroup = ({
  size = 'large',
  name,
  label,
  labelAll,
  options,
  selectedOption,
  onChangeOption,
  sx,
}: RadioToggleGroupProps) => {
  const labelId = useId();

  return (
    <FormControl sx={sx}>
      <Stack
        spacing={1.5}
        direction="column"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <FormLabel id={labelId}>{label}</FormLabel>

        <RadioGroup
          value={selectedOption}
          aria-labelledby={labelId}
          name={name}
          onChange={(e, value) => {
            onChangeOption(name, value);
          }}
        >
          <Stack
            spacing={0.25}
            padding={0.75}
            direction="row"
            alignItems={'center'}
            sx={{
              borderRadius: 1,
              background: ({ palette }) => palette.grey[100],
              display: 'inline-flex',
            }}
          >
            <RadioOption value="" label={labelAll} size={size} />
            {options &&
              options.length > 0 &&
              options.map((option) => (
                <RadioOption
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  size={size}
                />
              ))}
          </Stack>
        </RadioGroup>
      </Stack>
    </FormControl>
  );
};

const OptionButton = styled(ContainedButton)(({ theme: { palette, spacing } }) => ({
  // Would prefer to not use !important, but it's hard to override
  borderRadius: spacing(0.5),
  lineHeight: 1,
  border: 'none !important',

  '&.MuiButton-sizeSmall': {
    // paddingBlock: `${spacing(0.875)} !important`, // Ensures consitency with height of search input (40px), but is too small for design
    paddingBlock: `${spacing(1.375)} !important`,
    paddingInline: `${spacing(2)}  !important`,
  },

  '&.MuiButton-sizeMedium': {
    paddingBlock: `${spacing(1.5)} !important`,
    paddingInline: `${spacing(3)} !important`,
  },

  '&.MuiButton-sizeLarge': {
    paddingBlock: `${spacing(2)} !important`,
    paddingInline: `${spacing(4.375)} !important`,
  },

  '&.MuiButton-inactivePrimary': {
    borderColor: 'transparent',
    color: palette.grey[700],
    '&:hover': {
      color: palette.common.white,
    },
  },
}));

function RadioOption(
  props: Omit<FormControlLabelProps, 'control' | 'value'> & { value: string; size: Size }
) {
  const radioGroup = useRadioGroup();
  const { label, value, size } = props;
  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return (
    <FormControlLabel
      control={
        <Radio
          sx={{
            // Visually hide the radio button
            opacity: 0,
            position: 'absolute',
          }}
        />
      }
      label={
        <OptionButton
          component="div"
          size={size}
          color="primary"
          variant={checked ? 'contained' : 'inactive'}
          role={undefined}
          tabIndex={-1}
        >
          {label}
        </OptionButton>
      }
      value={value}
      checked={checked}
    />
  );
}
