import { Color } from '@mui/material';

export const mgPrisBlue: Color = {
  50: '#4D52E81A',
  100: '#4D52E833',
  200: '#4D52E84D',
  300: '#4D52E866',
  400: '#4D52E880',
  500: '#4D52E899',
  600: '#4D52E8B2',
  700: '#4D52E8CC',
  800: '#4D52E8',
  900: '#4D52E8',
  A100: '#F0F4FF',
  A200: '#F4F4F6',
  A400: '',
  A700: '',
};
