import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { SubRoute } from './types';
import { getListItemTextStyles } from './styles';

interface Props extends SubRoute {
  expandedView: boolean;
  onClick?: () => void;
}

function NavSubItemBase({ to, isActive, title, expandedView, onClick }: Props) {
  const listItemButtonStyles = useMemo(() => getListItemButtonStyles(), []);
  const listItemDividerStyles = useMemo(() => getListItemDividerStyles(isActive), [isActive]);
  const listItemTextStyles = useMemo(() => getListItemTextStyles(expandedView), [expandedView]);

  return (
    <ListItem disablePadding>
      <ListItemButton
        to={to}
        component={Link}
        onClick={onClick}
        selected={isActive}
        sx={listItemButtonStyles}
      >
        <Divider orientation="vertical" sx={listItemDividerStyles} />

        <ListItemText
          disableTypography
          primary={<Typography sx={{ fontWeight: isActive ? 800 : 500 }}>{title}</Typography>}
          sx={listItemTextStyles}
        />
      </ListItemButton>
    </ListItem>
  );
}

export const NavSubItem = memo(NavSubItemBase);

export const getListItemButtonStyles = (): SxProps<Theme> => ({
  height: '40px',
  color: (theme) => theme.palette.grey.A400,
  backgroundColor: 'transparent !important',
  padding: 0,
});

export const getListItemDividerStyles = (isActive: boolean): SxProps<Theme> => ({
  borderColor: (theme) => (isActive ? theme.palette.grey.A400 : theme.palette.grey[100]),
  borderRightWidth: 2,
});
