import { Typography, TypographyProps } from '@mui/material';
import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { useMemo } from 'react';
import EmbeddedYoutubeVideo from './EmbeddedYoutubeVideo';
import InlineExternalLink from './InlineExternalLink';
import InlineInternalLink from './InlineInternalLink';
import SanityImage from './SanityImage';
import SanityTable from './SanityTable';
import { SanityBlockContent } from '@dap-sanity/types';
import { getHrefValueForLink } from '@dap-sanity/utils';

interface Props extends Pick<TypographyProps, 'variant' | 'component' | 'sx'> {
  body: SanityBlockContent[];
}

const portableComponents: Partial<PortableTextReactComponents> = {
  marks: {
    link: ({ children, value }) => {
      const { href } = value;
      if (!href) {
        return children;
      }
      if (href.startsWith('/')) {
        return <InlineInternalLink to={href}>{children}</InlineInternalLink>;
      }
      return <InlineExternalLink href={getHrefValueForLink(href)}>{children}</InlineExternalLink>;
    },
  },
  types: {
    image: ({ value }) => <SanityImage imgSrc={value} />,
    table: ({ value }) => <SanityTable {...value} />,
    videoSrc: ({ value }) => <EmbeddedYoutubeVideo url={value.url} />,
    span: ({ value }) => <span>{value.text}</span>,
  },
  block: {
    h1: ({ children }) => (
      <Typography variant="h4" component="h1" marginTop={8}>
        {children}
      </Typography>
    ),
    h2: ({ children }) => (
      <Typography variant="h4" component="h2" marginTop={7}>
        {children}
      </Typography>
    ),
    h3: ({ children }) => (
      <Typography variant="h6" component="h3" marginTop={6}>
        {children}
      </Typography>
    ),
    h4: ({ children }) => (
      <Typography variant="h6" component="h4" marginTop={5}>
        {children}
      </Typography>
    ),
    h5: ({ children }) => (
      <Typography variant="h6" component="h5" marginTop={4}>
        {children}
      </Typography>
    ),
    h6: ({ children }) => (
      <Typography variant="h6" component="h6">
        {children}
      </Typography>
    ),
    blockquote: ({ children }) => (
      <Typography
        variant="h4"
        component="blockquote"
        sx={(theme) => ({
          marginBlock: 10,
          marginInline: 6,
          fontStyle: 'italic',
          display: 'flex',
          fontWeight: '400',
          lineHeight: 30.72 / 24,
          [theme.breakpoints.up('md')]: {
            marginInline: 15,
          },
          '&::before': {
            content: '""',
            width: theme.spacing(1.5),
            display: 'inline-block',
            marginRight: 3,
            borderRadius: 0.5,
            background: theme.palette.gradient.secondary,
          },
        })}
      >
        {children}
      </Typography>
    ),
  },
};

function SanityBody({ body, variant = 'body1', component = 'section', sx }: Props): JSX.Element {
  const bodyWithoutEmptyParagraphs = useMemo(
    () =>
      body &&
      body.filter(
        ({ children, _type }) =>
          _type !== 'block' || !!children?.some(({ text, _type }) => _type !== 'span' || !!text)
      ),
    [body]
  );

  return (
    <Typography variant={variant} component={component} maxWidth="md" sx={sx}>
      <PortableText value={bodyWithoutEmptyParagraphs} components={portableComponents} />
    </Typography>
  );
}

export default SanityBody;
