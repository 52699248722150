import { useMemo, useState } from 'react';
import { Typography, Stack, Card, Divider } from '@mui/material';
import { ContainedButton, SearchInputField } from '@shared/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { SanityNewsArticlePreview } from '@dap-sanity/types';
import { SanityPageHeader } from '../pageContent';
import { useSearchParams } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import { ActiveFilters, FilterDrawer } from '../components';
import Fuse from 'fuse.js';
import NewsList from './NewsList';
import { getAllNewsRoute } from '@dap-common/consts';
import { CheckboxLabelWithCount } from '@dap-common/ui';
import { useDebounce } from 'use-debounce';
import { SanityBody } from '../pageContent';
import type { PortableTextBlock } from '@portabletext/types';

interface Props {
  title: string;
  info?: PortableTextBlock[];
  items: SanityNewsArticlePreview[];
}

const fuseOptions = {
  keys: ['title', 'intro', 'tags'],
  includeScore: true,
  includeMatches: true,
  ignoreLocation: true,
  threshold: 0.3,
};

const NewsPLP: React.FC<Props> = ({ items, title, info }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(['newsArticles', 'common']);
  const tags = searchParams.get('tags');
  const query = searchParams.get('q');
  const [queryValue] = useDebounce(query ?? '', 1000 / 30); // Debounce query from url to improve performance
  const [showFilters, setShowFilters] = useState(false);

  // Iterate items and collect the unique article tags that are used by the content, sorted ascending by title
  const allTags = useMemo(() => {
    const tags = items.reduce((carry, item) => {
      // Iterate the tags of the current item and add them to the carry array, if they are not already there
      // If they are there, increase the count of the tag
      if (!item.articleTags) {
        return carry;
      }

      const nextCarry = item.articleTags.reduce((c, tag) => {
        const existingTag = c.find((t) => tag.slug === t.slug);

        if (existingTag) {
          existingTag.count++;
          return c;
        }

        return [...c, { ...tag, count: 1 }];
      }, carry);

      return nextCarry;
    }, [] as { title: string; slug: string; count: number }[]);

    // Sort tags by title alphabetically, then map them to the CheckboxGroupOptionItem type
    return tags.sort((a, b) => a.title.localeCompare(b.title));
  }, [items]);

  // Map array of tags to CheckboxGroupOptionItem type
  const mappedTags = useMemo(() => {
    return allTags.map((tag) => ({
      value: tag.slug,
      label: <CheckboxLabelWithCount label={tag.title} count={tag.count} />,
    }));
  }, [allTags]);

  const hasTags = allTags.length > 0;

  // Filter items by tags
  const itemsByTags = useMemo(() => {
    if (!tags) {
      return items;
    }

    const i = items.filter((item) => {
      if (!item.articleTags) {
        return false;
      }

      return item.articleTags.some((tag) => tags.split(',').includes(tag.slug));
    });

    return i;
  }, [items, tags]);

  // Create a new Fuse instance with the items filtered by tags
  const fuse = useMemo(() => {
    const f = new Fuse(itemsByTags, fuseOptions);

    return f;
  }, [itemsByTags]);

  const updateSearchParams = ({
    params = {},
    clearParams,
  }: {
    params?: Record<string, string>;
    clearParams?: string[];
  }) => {
    // Convert URLSearchParams object to object of key, value pairs
    const currentParams: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      currentParams[key] = value;
    }

    const nextParams = {
      ...currentParams,
      ...params,
    };

    clearParams?.forEach((param) => {
      delete nextParams[param];
    });

    setSearchParams(nextParams);
  };

  // Filter items based on the selected tags from query string
  const filteredItems = useMemo(() => {
    if (!queryValue) {
      return itemsByTags;
    }

    const itemsByQuery = queryValue
      ? fuse.search(queryValue).map((result) => {
          return result.item;
        })
      : itemsByTags;

    return itemsByQuery;
  }, [itemsByTags, queryValue, fuse]);

  // Create list of tags that are checked
  const activeTags = useMemo(
    () =>
      tags
        ? (tags
            .split(',')
            .map((t) => {
              const label = allTags.find((tag) => tag.slug === t)?.title;

              if (!label) return undefined;

              return {
                value: t,
                label,
              };
            })
            .filter(Boolean) as { value: string; label: string }[])
        : [],
    [tags]
  );

  // Handles changes to the input field
  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value === '') {
      updateSearchParams({ clearParams: ['q'] });
      return;
    }

    updateSearchParams({ params: { q: event.target.value } });
  };

  return (
    <Stack spacing={3}>
      <Card sx={{ padding: 6 }}>
        {title && <SanityPageHeader header={title} />}
        {info && (
          <SanityBody
            body={info}
            variant="h6"
            sx={{
              fontWeight: 500,
              marginTop: 3,
            }}
          />
        )}
        <Stack spacing={3} direction="row" mt={3}>
          <SearchInputField
            variant="filled"
            value={query ?? ''}
            onChange={handleQueryChange}
            onReset={() => updateSearchParams({ clearParams: ['q'] })}
            placeholder={t(messages.newsArticles.searchPlaceholder)}
          />
          {hasTags && (
            <ContainedButton
              startIcon={<TuneIcon sx={{ transform: 'rotate(90deg)' }} />}
              onClick={() => setShowFilters(!showFilters)}
              size="small"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {showFilters
                ? t(`common:${messages.common.filter.close}`)
                : t(`common:${messages.common.filter.open}`)}
            </ContainedButton>
          )}
        </Stack>
      </Card>
      <FilterDrawer
        title={t(`common:${messages.common.filter.name}`)}
        open={showFilters}
        onClose={() => setShowFilters(false)}
        onChangeFilter={(name, value) => {
          if (value.length === 0) {
            updateSearchParams({ clearParams: ['tags'] });
            return;
          }

          updateSearchParams({ params: { tags: value.join(',') } });
        }}
        filters={[
          {
            name: 'tags',
            items: mappedTags,
            selectedItems: tags?.split(',') ?? [],
          },
        ]}
      />
      <Stack spacing={3} direction="row" width="100%">
        <Card sx={{ padding: 4, flex: '1 1 auto' }}>
          <Stack direction="row" marginBlockEnd={3}>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: 'nowrap',
                flexShrink: 0,
                lineHeight: '32px',
              }}
            >
              {t(messages.newsArticles.results, {
                count: filteredItems.length,
                total: items.length,
              })}
            </Typography>
            <Divider orientation="vertical" sx={{ marginInline: 3, height: '32px' }} />
            <ActiveFilters
              filters={activeTags}
              onClickFilter={(value) => {
                const nextTags = tags ? tags.split(',').filter((t) => t !== value) : [];

                if (nextTags.length === 0) {
                  updateSearchParams({ clearParams: ['tags'] });
                  return;
                }

                updateSearchParams({ params: { tags: nextTags.join(',') } });
              }}
              resetLabel={t(`common:${messages.common.filter.clear}`)}
              onClickReset={() => {
                setSearchParams({});
              }}
              query={query ?? undefined}
              onClearQuery={() => {
                updateSearchParams({ clearParams: ['q'] });
              }}
            />
          </Stack>
          {filteredItems && <NewsList newsArticles={filteredItems} newsUrl={getAllNewsRoute()} />}
        </Card>
      </Stack>
    </Stack>
  );
};

export default NewsPLP;
