import { Theme } from '@mui/material';

import { componentsStyles as componentsStylesV2 } from './../dap/componentsStyles';

export const componentsStyles: Theme['components'] = {
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        boxShadow: 'none', // Flattens design
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none', // Flattens design
      },
      rounded: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 2,
      }),
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        '&.MuiContainer-maxWidthMd': {
          maxWidth: '800px',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: () => ({
        letterSpacing: 'inherit',
      }),
    },
  },
  // Override border color of contained brand button
  // Destructure the existing styles and add a new variant, overriding the border color of the brand button
  MuiButton: {
    ...(componentsStylesV2?.MuiButton ?? {}),
    variants: [
      ...(componentsStylesV2?.MuiButton?.variants ?? []),
      {
        props: { color: 'brand', variant: 'contained' },
        style: ({ theme }) => ({
          borderColor: theme.palette.brand.main,
          color: theme.palette.brand.contrastText,
          '&:hover': {
            backgroundColor: `oklch(from ${theme.palette.brand.main} calc(l * 0.8) c h)`,
            borderColor: `oklch(from ${theme.palette.brand.main} calc(l * 0.8) c h)`,
            color: theme.palette.brand.contrastText,
            // borderColor: theme.palette.brand.main,
            // boxShadow: '0 0 10px 2px deeppink',
          },
          // '&:focus-visible': {
          // boxShadow: theme.shadows[1],
          // outline: `1px solid ${theme.palette.brand.main}`,
          // outlineOffset: 2,
          // },
        }),
      },
    ],
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({ theme: { shape, spacing, palette }, ownerState }) => ({
        padding: spacing(0.75),
        // backgroundColor: palette.primary.A200,
        backgroundColor: palette.grey[100],
        borderRadius: shape.borderRadius * 2,
        gap: spacing(ownerState.size === 'large' ? 0.75 : 0.25),
      }),
      grouped: ({ theme: { spacing, palette, shape, shadows } }) => ({
        color: palette.text.secondary,
        background: 'transparent',
        border: 'none !important',
        fontWeight: 600,

        '&.MuiToggleButtonGroup-grouped': {
          borderRadius: shape.borderRadius,
          '&:hover,:focus': {
            boxShadow: shadows[1],
            color: palette.common.white,
            background: palette.grey[900],
          },
        },
        '&.Mui-selected': {
          '&.MuiToggleButton-primary, &.MuiToggleButton-standard': {
            color: palette.common.white,
            background: palette.gradient.primary,
          },
          '&:hover': {
            boxShadow: shadows[1],
            color: palette.common.white,
            background: palette.grey[900],
          },
        },
      }),
    },
  },
  MuiToggleButton: {
    defaultProps: {
      disableFocusRipple: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderWidth: ownerState.size === 'large' ? 2 : 1,
        borderStyle: 'solid',
        borderRadius: theme.spacing(ownerState.size === 'large' ? 1 : 0.5),
        textTransform: 'none',
        background: theme.palette.common.white,
        fontWeight: 500,

        '&.Mui-selected': {
          borderColor: theme.palette.primary.main,
          background: theme.palette.common.white,
        },
        '&:hover, &.Mui-selected:hover': {
          borderWidth: ownerState.size === 'large' ? 2 : 1,
          borderStyle: 'solid',
          boxShadow: theme.shadows[1],
          transition: 'none', // Remove for smoother border transition
          borderColor: theme.palette.grey[900],
          color: theme.palette.common.white,
          background: theme.palette.grey[900],
        },
        '&:focus-visible': {
          boxShadow: theme.shadows[1],
        },
        '&:disabled': {
          borderWidth: ownerState.size === 'large' ? 2 : 1,
          borderStyle: 'solid',
          background: theme.palette.grey.A100,
          color: theme.palette.text.disabled,
          borderColor: theme.palette.grey.A100,
        },
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.typography.body2,
        paddingInline: theme.spacing(2.75),
        paddingBlock: theme.spacing(1),
      }),
      sizeMedium: ({ theme }) => ({
        ...theme.typography.body1,
        paddingInline: theme.spacing(3),
        paddingBlock: theme.spacing(1.5),
      }),
      sizeLarge: ({ theme }) => ({
        ...theme.typography.body1,
        paddingInline: theme.spacing(3.25),
        paddingBlock: theme.spacing(2),
      }),
      selected: ({ theme }) => ({
        borderColor: theme.palette.primary.main,
        background: theme.palette.common.white,
      }),
    },
    // css state classes didnt work for primary, secondary, standard
    variants: [
      {
        props: { color: 'primary' },
        style: ({ theme: { palette } }) => ({
          color: palette.primary.main,
          '&.Mui-selected': {
            borderColor: palette.primary.main,
          },
        }),
      },
      {
        props: { color: 'secondary' },
        style: ({ theme: { palette } }) => ({
          color: palette.secondary.main,
          '&.Mui-selected': {
            borderColor: palette.secondary.main,
          },
        }),
      },
      {
        props: { color: 'warning' },
        style: ({ theme: { palette } }) => ({
          color: palette.warning.main,
          '&.Mui-selected': {
            borderColor: palette.warning.main,
          },
        }),
      },
      {
        props: { color: 'success' },
        style: ({ theme: { palette } }) => ({
          color: palette.success.main,
          '&.Mui-selected': {
            borderColor: palette.success.main,
          },
        }),
      },
    ],
  },
};
