import { SxProps, Theme } from '@mui/material';

export const getListItemStyles = (expandedView: boolean): SxProps<Theme> => ({
  height: '48px',
  width: expandedView ? '100%' : '48px',
  mb: '0.5rem',
});

export const getListItemButtonStyles = (
  isActive: boolean,
  overridingStyles?: SxProps<Theme>
): SxProps<Theme> => {
  return {
    height: '48px',
    color: (theme) => (isActive ? theme.palette.common.white : theme.palette.grey.A400),
    background: (theme) => (isActive ? theme.palette.gradient.primary : 'none'),
    borderRadius: 3,
    justifyContent: 'center',
    ...overridingStyles,
  };
};

export const getListItemIconStyles = (isActive: boolean): SxProps<Theme> => ({
  color: (theme) => (isActive ? theme.palette.common.white : theme.palette.grey.A400),
  minWidth: 20,
});

export const getListItemTextStyles = (expandedView: boolean): SxProps<Theme> => ({
  marginLeft: '1rem',
  ...(!expandedView && { display: 'none' }),
});
