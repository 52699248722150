import { PropsWithChildren, useMemo } from 'react';
import { Box, Drawer, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { SystemStyleObject } from '@mui/system';

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  appBarHeight?: number;
  collapsedWidth?: number;
  expandedWidth?: number;
  hasBorderTopRightRadius?: boolean;
}

export const COLLAPSED_SIDEBAR_WIDTH = 96;
export const EXPANDED_SIDEBAR_WIDTH = 300;
export const APP_BAR_HEIGHT = 66;

export function Sidebar({
  isOpen,
  toggleOpen,
  appBarHeight = APP_BAR_HEIGHT,
  collapsedWidth = COLLAPSED_SIDEBAR_WIDTH,
  expandedWidth = EXPANDED_SIDEBAR_WIDTH,
  hasBorderTopRightRadius = false,
  children,
}: PropsWithChildren<Props>) {
  const openedStyles = useMemo(() => getOpenedStyles(expandedWidth), [expandedWidth]);
  const closedStyles = useMemo(() => getClosedStyles(collapsedWidth), [collapsedWidth]);

  const wrapperStyles = useMemo(() => getWrapperStyles(isOpen), [isOpen]);
  const drawerStyles = useMemo(
    () =>
      getDrawerStyles(isOpen ? openedStyles : closedStyles, appBarHeight, hasBorderTopRightRadius),
    [isOpen, openedStyles, closedStyles, appBarHeight, hasBorderTopRightRadius]
  );
  const stackStyles = useMemo(
    () => getStackStyles(isOpen ? openedStyles : closedStyles),
    [isOpen, openedStyles, closedStyles]
  );

  return (
    <Drawer anchor="left" sx={drawerStyles} variant="permanent">
      <Box sx={wrapperStyles}>
        <Stack sx={stackStyles}>{children}</Stack>

        <ToggleSidebarButton isOpen={isOpen} toggleOpen={toggleOpen} appBarHeight={appBarHeight} />
      </Box>
    </Drawer>
  );
}

const ToggleSidebarButton = ({
  isOpen,
  toggleOpen,
  appBarHeight = 80,
}: Pick<Props, 'isOpen' | 'toggleOpen' | 'appBarHeight'>) => {
  return (
    <Box position="absolute" left="100%" zIndex={(theme) => theme.zIndex.drawer + 1}>
      <Box position="fixed" top={`${appBarHeight + 50}px`}>
        <IconButton
          color="primary"
          onClick={toggleOpen}
          sx={{
            width: '22px',
            height: '22px',
            left: '-11px',
            padding: 0,
            borderRadius: '50%',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            bgcolor: (theme) => theme.palette.common.white,
            '&:hover': {
              bgcolor: (theme) => theme.palette.common.white,
            },
          }}
        >
          {isOpen ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
        </IconButton>
      </Box>
    </Box>
  );
};

const getWrapperStyles = (isOpen: boolean): SxProps<Theme> => ({
  height: '100%',
  position: 'absolute',
  left: 0,
  boxShadow: (theme) => (isOpen ? theme.shadows[5] : null),
});

const getStackStyles = (openOrClosedStyles: SystemStyleObject<Theme>): SxProps<Theme> => ({
  height: '100%',
  ...openOrClosedStyles,
});

const getDrawerStyles = (
  openOrClosedStyles: SystemStyleObject<Theme>,
  appBarHeight: number,
  hasBorderTopRightRadius?: boolean
): SxProps<Theme> => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...openOrClosedStyles,

  '& .MuiDrawer-paper': {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    border: 0,
    height: `calc(100% - ${appBarHeight}px)`,
    top: appBarHeight,
    borderTopRightRadius: (theme) => (hasBorderTopRightRadius ? theme.spacing(1) : undefined),
    ...openOrClosedStyles,
  },
});

const getOpenedStyles = (width: number): SystemStyleObject<Theme> => ({
  width,
  transition: (theme) =>
    theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  overflowX: 'hidden',
});

const getClosedStyles = (width: number): SystemStyleObject<Theme> => ({
  width,
  transition: (theme) =>
    theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  overflowX: 'hidden',
});
