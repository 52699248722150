import { useMemo } from 'react';
import {
  Attachment,
  EventInformation,
  KeyInfo,
  Person,
  SanityBlockContent,
} from '@dap-sanity/types';
import { Stack, Typography, Paper, Box, Container } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { useState } from 'react';
import { PublisherInfo, SanityBody, SanityImage } from './index';
import AttachmentsCard from './AttachmentsCard';
import EventInformationCard from './EventInformationCard';
import KeyInfoCard from './KeyInfoCard';
import PdfModal from './PdfModal';
import { useTheme } from '@mui/system';
import { GoBackLink } from '@dap-common/ui';
import { TagsList } from '../components';
import { type Breadcrumb } from './SanityPageHeader';
import { Breadcrumbs } from '@shared/components';
import { Tag } from '@dap-sanity/types';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { getAllNewsRoute } from '@dap-common/consts';

interface Props {
  title: string;
  intro: string;
  image?: SanityImageSource;
  body?: SanityBlockContent[];
  attachments?: Attachment[];
  keyInfo?: KeyInfo;
  author?: Person;
  publishedAt?: string;
  eventInformation?: EventInformation;
  breadcrumbs?: Breadcrumb[];
  articleTags?: Tag[];
}

export default function SanityArticle({
  title,
  intro,
  image,
  body,
  attachments,
  keyInfo,
  author,
  publishedAt,
  eventInformation,
  breadcrumbs,
  articleTags,
}: Props): JSX.Element {
  const { t } = useTranslation('newsArticles');
  const { breakpoints } = useTheme();

  const showKeyInfo =
    !!keyInfo?.email?.length || !!keyInfo?.link?.length || !!keyInfo?.text?.length;
  const showAttachments = !!attachments?.length;
  const showEventInformationCard = !!eventInformation;

  const [activePdfAttachment, setActivePdfAttachment] = useState<Attachment | null>(null);

  const mappedTags = useMemo(
    () =>
      articleTags?.map((tag) => ({
        title: tag.title,
        slug: tag.slug,
        href: `${getAllNewsRoute()}?tags=${tag.slug}`,
      })) ?? [],
    [articleTags]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{
        [breakpoints.up('lg')]: {
          flexDirection: 'row',
        },
      }}
    >
      <Stack spacing={3} flex={'1 1 0'}>
        <Paper sx={{ paddingInline: 3, paddingBlock: 8 }}>
          <Container maxWidth="md" disableGutters sx={{ marginInline: 'auto' }}>
            {!breadcrumbs && <GoBackLink />}
            {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}

            <Typography
              variant="h1"
              mb={4}
              mt={5}
              sx={{
                hyphens: 'auto',
                textWrap: 'balance',
              }}
            >
              {title}
            </Typography>
            <Typography variant="h6" component="p" sx={{ lineHeight: 21.24 / 18, marginBottom: 5 }}>
              {intro}
            </Typography>
            {(author || publishedAt) && (
              <PublisherInfo author={author} publishedAt={publishedAt} sx={{ marginBottom: 5 }} />
            )}
            {image && <SanityImage imgSrc={image} />}
            {body && <SanityBody body={body} />}

            {mappedTags && (
              <TagsList
                sx={{ marginTop: 10 }}
                tags={mappedTags}
                title={t(messages.newsArticles.relatedTags)}
              />
            )}
          </Container>
        </Paper>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          [breakpoints.up('lg')]: {
            width: '400px',
          },
        }}
      >
        {(showKeyInfo || showAttachments || showEventInformationCard) && (
          <Stack
            // item
            position="sticky"
            top="66px"
            style={{
              height: 'min-content',
            }}
          >
            <Stack spacing={1}>
              {showEventInformationCard && <EventInformationCard {...eventInformation} />}
              {showAttachments && (
                <AttachmentsCard
                  attachments={attachments}
                  onClickPdfAttachment={setActivePdfAttachment}
                />
              )}
              {showKeyInfo && <KeyInfoCard keyInfo={keyInfo} />}
            </Stack>
          </Stack>
        )}
        {!!activePdfAttachment && (
          <PdfModal url={activePdfAttachment.url} onClose={() => setActivePdfAttachment(null)} />
        )}
      </Stack>
    </Box>
  );
}
