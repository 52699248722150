import { List, SxProps, Theme } from '@mui/material';
import { NavItem } from './NavItem';
import { NavItemWithSubRoutes } from './NavItemWithSubRoutes';
import { NavRoute, NavWithSubRoutes } from './types';

interface Props {
  navRoutes: (NavRoute | NavWithSubRoutes)[];
  expandedView: boolean;
  overridingStyles?: SxProps<Theme>;
  expandView: () => void;
  onClickCallback?: (navRoute: NavRoute | NavWithSubRoutes) => void;
}

export function Navigation({
  navRoutes,
  expandedView,
  overridingStyles,
  expandView,
  onClickCallback,
}: Props) {
  return (
    <List sx={{ padding: 3 }} component="nav">
      {navRoutes.map((navRoute) => {
        if ('subRoutes' in navRoute) {
          const { title, Icon, to, isActive, subRoutes, initialOpen } = navRoute;

          return (
            <NavItemWithSubRoutes
              title={title}
              Icon={Icon}
              to={to}
              isActive={isActive}
              subRoutes={subRoutes}
              expandView={expandView}
              expandedView={expandedView}
              key={title}
              initialOpen={initialOpen}
              overridingStyles={overridingStyles}
            />
          );
        }

        const { title, Icon, to, isActive } = navRoute;

        return (
          <NavItem
            key={to}
            title={title}
            Icon={Icon}
            onClick={onClickCallback && (() => onClickCallback(navRoute))}
            to={to}
            isActive={isActive}
            expandedView={expandedView}
            overridingStyles={overridingStyles}
          />
        );
      })}
    </List>
  );
}
