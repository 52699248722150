import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { Person } from '../person/personTypes';
import { Attachment, KeyInfo, SanityBlockContent } from './commonFieldTypes';
import { ModuleType } from '../modules/moduleTypes';
import { Tag } from '../objects/tag';

export enum ArticleType {
  standard = 'article',
  module = 'moduleArticle',
  event = 'eventArticle',
  service = 'serviceArticle',
}

export interface SanityArticlePreview {
  id: string;
  title: string;
  intro: string;
  url: string;
  image: SanityImageSource;
}

export interface SanityModuleArticle extends SanityArticlePreview {
  body?: SanityBlockContent[];
  attachments?: Attachment[];
  keyInfo?: KeyInfo;
  tags?: string[];
  categoryTitle?: string;
}

export interface SanityStandardArticle extends SanityArticlePreview {
  body?: SanityBlockContent[];
  attachments?: Attachment[];
  keyInfo?: KeyInfo;
  tags?: string[];
  author?: Person[];
  categoryTitle?: string;
  publishAt: string;
  module: string;
  articleTags: Tag[];
}

export interface SanityLatestArticle {
  publishedAt: string;
  id: string;
  url: string;
  title: string;
  intro: string;
  _type: ArticleType;
  module?: ModuleType;
  moduleRef?: {
    slug: string;
    _type: string;
  };
  category?: { slug: string };
}
