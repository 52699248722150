export const colors = {
  midnightBlue: {
    50: '#EEEAFF',
    100: '#DCD4FE',
    200: '#CBBFFE',
    300: '#B9AAFE',
    400: '#A894FD',
    500: '#977FFD',
    600: '#856AFD',
    700: '#7455FD',
    800: '#623FFC',
    900: '#512AFC',
    A100: '#F6F7FF',
    A200: '#F4F4F6',
    A400: '',
    A700: '',
  },
  saffron: {
    50: '#FFF7E7',
    100: '#FFEFCF',
    200: '#FFE6B7',
    300: '#FFDE9F',
    400: '#FFD687',
    500: '#FFCE6F',
    600: '#FFC657',
    700: '#FFBD3F',
    800: '#FFB527',
    900: '#FFAD0F',
    A100: '#E37500',
    A200: '',
    A400: '',
    A700: '',
  },
  grey: {
    50: '#EAEAED',
    100: '#D4D4DB',
    200: '#BFBFC8',
    300: '#A9AAB6',
    400: '#9494A4',
    500: '#7F7F92',
    600: '#696A80',
    700: '#54556D',
    800: '#3E3F5B',
    900: '#292A49',
    A100: '#EFEFEF',
    A200: '#5A6792B2',
    A400: '#5A6792',
    A700: '',
  },
  alert: {
    50: '#FF6A551A',
    100: '#FF6A5533',
    200: '#FF6A554D',
    300: '#FF6A5566',
    400: '#FF6A5580',
    500: '#FF6A5599',
    600: '#FF6A55B2',
    700: '#FF6A55CC',
    800: '#FF6A55E5',
    900: '#FF6A55',
    A100: '#FDF2F8',
    A200: '#FFA98F',
    A400: '#FF754C',
    A700: '',
  },
  green: {
    50: '#20A32D1A',
    100: '#20A32D33',
    200: '#20A32D4D',
    300: '#20A32D66',
    400: '#20A32D80',
    500: '#20A32D99',
    600: '#20A32DB2',
    700: '#20A32DCC',
    800: '#20A32DE5',
    900: '#20A32D',
    A100: '',
    A200: '',
    A400: '#EDF2F0',
    A700: '#95BFAB',
  },
  skyBlue: {
    50: '#B1E5FC1A',
    100: '#B1E5FC33',
    200: '#B1E5FC4D',
    300: '#B1E5FC66',
    400: '#B1E5FC80',
    500: '#B1E5FC99',
    600: '#B1E5FCB2',
    700: '#B1E5FCCC',
    800: '#B1E5FCE5',
    900: '#B1E5FC',
    A100: '',
    A200: '',
    A400: '',
    A700: '',
  },
  black: '#000',
  white: '#FFFFFF',
};
