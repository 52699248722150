import { Stack, useTheme, styled } from '@mui/material';
import { Chip } from '@shared/components';
import CloseIcon from '@mui/icons-material/Close';
import { TrashIcon } from '@shared/custom-icons';

type Filter = {
  label: string;
  value: string;
};

interface TopicHeaderProps {
  filters: Filter[];
  onClickFilter: (value: string) => void;
  resetLabel?: string;
  onClickReset?: () => void;
  query?: string;
  onClearQuery?: () => void;
}

export function ActiveFilters({
  filters,
  onClickFilter,
  onClickReset,
  resetLabel,
  query,
  onClearQuery,
}: TopicHeaderProps) {
  const { palette } = useTheme();

  if (!filters.length && !query) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="flex-start">
      <List>
        {filters.map((f) => (
          <ListItem key={f.value}>
            <Chip
              backgroundColor={palette.primary[600]}
              textColor={palette.common.white}
              label={f.label}
              onDelete={() => onClickFilter(f.value)}
              size="medium"
              sx={{ whiteSpace: 'nowrap' }}
              deleteIcon={<CloseIcon />}
            />
          </ListItem>
        ))}
        {query && onClearQuery && (
          <ListItem key="__query">
            <Chip
              deleteIcon={<CloseIcon />}
              backgroundColor={palette.primary[600]}
              textColor={palette.common.white}
              size="medium"
              onDelete={() => onClearQuery()}
              sx={{
                whiteSpace: 'nowrap',
                maxWidth: '23ch', // Because Michael Jordan said so
              }}
              label={
                <span
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {query}
                </span>
              }
            />
          </ListItem>
        )}
      </List>
      {resetLabel && onClickReset && (
        <Chip
          deleteIcon={<TrashIcon />}
          backgroundColor={palette.primary[100]}
          textColor={palette.primary[700]}
          size="medium"
          onDelete={() => onClickReset()}
          sx={{
            whiteSpace: 'nowrap',
            flexShrink: 0,
          }}
          label={resetLabel}
        />
      )}
    </Stack>
  );
}

const List = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginInlineEnd: theme.spacing(1),
}));

const ListItem = styled('li')(({ theme }) => ({}));
