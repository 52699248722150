import { Color } from '@mui/material';

export const midnightBlue: Color = {
  50: '',
  100: '#F3F0FF',
  200: '#DAD2FE',
  300: '#BDAEFE',
  400: '#947CFD',
  500: '#6F4FFD',
  600: '#522CFC',
  700: '#2500CC',
  800: '#1A008F',
  900: '#130066',
  // Keep accent colors from DAP theme, but they might be off, since the palette differs
  A100: '#F6F7FF',
  A200: '#F4F4F6',
  A400: '',
  A700: '',

  // Shift accent colors to one step darker from the base palette
  // A100: '#DAD2FE',
  // A200: '#BDAEFE',
  // A400: '#6F4FFD',
  // A700: '#1A008F',

  // Colors defined for debugging accent colors
  // A100: '#eeff70',
  // A200: '#eeef20',
  // A400: '#bfd200',
  // A700: '#55a630',
};

export const neutral: Color = {
  50: '#FAFAFA',
  100: '#F4F4F6',
  200: '#D4D4DB',
  300: '#A9AAB6',
  400: '#9494A4',
  500: '#7F7F92',
  600: '#696A80',
  700: '#54556D',
  800: '#3E3F5B',
  900: '#292A49',
  // Since no accents are defined, we shift the accent colors to one step darker
  A100: '#D4D4DB',
  A200: '#A9AAB6',
  A400: '#7F7F92',
  A700: '#3E3F5B',
  // Colors defined for debugging accent colors
  // A100: '#ffccd5',
  // A200: '#ffb3c1',
  // A400: '#ff758f',
  // A700: '#a4133c',
};

export const primaryText = '#29333D';
export const secondaryText = '#5A6792';
